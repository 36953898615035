<template>
	<div>
		<a-form-item :label="$t('table.applyInfo.loanApplyLabel.branchOffice')" class="selet-branch">
			<a-select v-model="officeId" @change="handleChangeLoanType" class="select" style="width: 200px; display: inline-block">
				<a-select-option :value="item.id" v-for="item in branchOfficeList" :key="item.id">{{ item.storeName }}</a-select-option>
			</a-select>
		</a-form-item>
		<a-table
			class="thead-none"
			:columns="columns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="salesList"
			:pagination="false"
			bordered
		>
			<span slot="action" slot-scope="text, columnData">
				<a @click="handleEdit(columnData)">{{ $t('page.edit') }}</a>
			</span>
		</a-table>
		<a-modal v-drag-modal v-model="createModalShow" :title="$t('page.editSalesman')" :destroyOnClose="true" width="800px" @ok="handleCreateModalOk">
			<a-form-model-item label="Update Marketers" class="selet-branch">
				<a-tag v-for="(item, idx) in marketerList" :key="item.marketer" closable @close="delSalesmen(idx)">{{ String(item.marketerName) }}</a-tag>
				<a-icon type="plus-square" class="add-sales" @click="searchUserList" />
			</a-form-model-item>
		</a-modal>
		<a-modal
			v-drag-modal
			v-model="userModal.show"
			:destroyOnClose="true"
			title="Add Marketers"
			width="80%"
			ok-text="ok"
			cancel-text="cancel"
			@ok="submitFrom"
		>
			<!-- <a-form-model layout="inline">
				<a-form-model-item :label="$t('table.Permission.User.username')">
					<a-input v-model="userModal.userId"></a-input>
				</a-form-model-item>
				<a-form-model-item>
					<a-button type="primary" @click="searchUserList" v-t.preserve="'operate.search'"></a-button>
				</a-form-model-item>
			</a-form-model> -->
			<a-table
				:columns="columns2"
				size="small"
				rowKey="userId"
				:dataSource="marketerAllList"
				:pagination="false"
				:row-selection="{
					fixed: true,
					columnWidth: 50,
					onChange: (k, r) => {
						;(this.selectedRowKeys = k), (this.selectedRows = r)
					},
					selectedRowKeys: selectedRowKeys
				}"
				bordered
			></a-table>
		</a-modal>
	</div>
</template>
<script>
import { apiBranchList } from '@/api/branch'
import { apiMarketerList, apiMarketerSave } from '@/api/staff'
export default {
	data() {
		return {
			officeId: '',
			salesList: [],
			saleId: '',
			marketerList: [],
			createModalShow: false,
			branchOfficeList: [],
			userModal: {
				userId: '',
				type: '',
				show: false
			},
			marketerAllList: [],
			selectedRowKeys: [],
			selectedRows: []
		}
	},
	computed: {
		columns() {
			return [
				{
					// 业务员姓名
					title: this.$t('table.EmployeeManagement.Salesman.displayName'),
					dataIndex: 'saleName',
					align: 'center'
				},
				{
					// 联系方式
					title: 'Marketers',
					dataIndex: 'marketers',
					align: 'center',
					customRender: (v, o) => {
						return v
							? v
									.map((item) => {
										return item.marketerName
									})
									.join(',')
							: '-'
					}
				},
				{
					// 操作
					title: this.$t('operate.operate'),
					scopedSlots: { customRender: 'action' },
					align: 'center'
				}
			]
		},
		columns2() {
			return [
				{
					title: this.$t('table.Permission.User.username'),
					dataIndex: 'userId',
					align: 'center'
				},
				{
					title: this.$t('table.Permission.User.displayName'),
					dataIndex: 'username',
					align: 'center'
				}
			]
		}
	},
	created() {
		this.$nextTick(() => {
			apiBranchList().then((res) => {
				this.branchOfficeList = res
				this.officeId = this.branchOfficeList[0].id
				this.marketerAllList = this.branchOfficeList[0].staffs
				this.getApiMarketerList(this.branchOfficeList[0].id)
			})
		})
	},
	methods: {
		getApiMarketerList(officeId) {
			apiMarketerList({ officeId }).then((result) => {
				this.salesList = result
			})
		},
		handleChangeLoanType(val) {
			this.officeId = val
			this.branchOfficeList.map((item) => {
				if (item.id === val) {
					this.marketerAllList = item.staffs
				}
			})
			this.getApiMarketerList(this.officeId)
		},
		handleEdit(obj) {
			this.createModalShow = true
			this.saleId = obj.sale
			this.marketerList = obj.marketers ? obj.marketers : []
		},
		delSalesmen(idx) {
			this.marketerList.splice(idx, 1)
		},
		handleCreateModalOk() {
			let params = {
				officeId: this.officeId, //门店id
				sale: this.saleId, //销售id
				marketers: this.marketerList
			}
			apiMarketerSave(params)
				.then((result) => {})
				.finally(() => {
					this.createModalShow = false
					this.getApiMarketerList(this.officeId)
				})
		},
		searchUserList(type) {
			this.selectedRowKeys = this.marketerList.map((value) => value.marketer)
			this.userModal.show = true
		},
		// 提交表单
		submitFrom() {
			if (this.selectedRows.length > 0) {
				this.marketerList = []
				this.selectedRows.map((item) => {
					this.marketerList.push({
						marketer: item.userId, //marketerid
						marketerName: item.username //marketer姓名
					})
				})
				this.userModal.show = false
				this.userModal.userId = ''
			}
		}
	}
}
</script>

<style lang="less">
.selet-branch {
	width: 100%;
	display: inline-block;
	margin-right: 30px;
	.ant-col {
		display: inline-block;
	}
	.select {
		width: 200px;
		display: inline-block;
		margin-left: 20px;
	}
}
.title button {
	float: right;
	margin-right: 10px;
}
.ant-select {
	width: 200px;
}
.add-sales {
	font-size: 24px;
	vertical-align: middle;
	color: #1890ff;
}
.ant-tag {
	padding: 5px 10px;
}
</style>
