import { _axios } from '@/plugins/axios'

export const apiMarketerList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/marketer`,
		method: method,
		data: dataJson
	})
}
export const apiMarketerSave = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/marketer/save`,
		method: method,
		data: dataJson
	})
}
export const apiSalesmanList = (dataJson, method = 'get') => {
	return _axios({
		url: `/car/staff/salesmanList`,
		method: method,
		data: dataJson
	})
}
export const apiEditSalesman = (dataJson, method = 'post') => {
	return _axios({
		url: `/car/staff/salesman`,
		method: method,
		data: dataJson
	})
}
export const apiDeleteSalesman = (dataJson, method = 'delete') => {
	return _axios({
		url: `/car/staff/salesman`,
		method: method,
		data: dataJson
	})
}
